import React, { Component } from 'react';
import classnames from 'classnames';
import i18n from 'i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Collapse } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import ISO6391 from 'iso-639-1';
import { STORAGE_VENUE_FRIENDLY_URL, STORAGE_VENUE_ID } from '../constants/storage';
import CategoryNav from './CategoryNav';
import ConfigContext from '../contexts/ConfigContext';
import FinishCallModal from './call/FinishCallModal';
import { getStorageItem, clearStorageItem } from '../util/Storage.js';
import { nsEvenly, nsVL, pushHistoryState } from '../util/Util.js';
import { withCookies } from 'react-cookie';
import {
  HOMEPAGE, VIEW_BOOKING, VIEW_BOOKING_BCC, VIEW_ABOUT, VIEW_USER_HOME,
  VIEW_MANAGE_BOOKING, VIEW_EVENT_VIEWER, VIEW_IMM_MODE, VIEW_IMM_MODE_TTS, VIEW_VIDEOCALL,
  VIEW_IN_PERSON_MODE, VIEW_IN_PERSON_MODE_PURE,
  VIEW_CHAT_HISTORY
} from '../constants/views.js'

const ACCOUNT = "account"
const LANG = "lang"
const CATEGORY_NAV = "categoryNav"
const PVS = "pvs"
const VIDEO_CALL = "videoCall"

class Navigation extends Component {
  constructor(props) {
    super(props)
    const sessionFilters = JSON.parse(sessionStorage.getItem("filters"))

    this.state = {
      showFinishCallModal: false,
      showLogoutAgentModal: false,
      search: sessionFilters ? sessionFilters.search : ""
    };

  }

  render() {
    const { t, hideSearch, licenseDtls, pir, cusJoined, productless, arsMode, user, isVideoCall, agentJoined, isAgent, isAdminUser,
      hideCatalogue, setShowInstantCallRequestModal, handleShowUserAuthModal, customerID, setShowInstantChatRequestModal, currentView,
      isWorkingHour, handleShowWorkingHoursModal, hideNavControls, setShowAudioOnlyRequestModal } = this.props;
    const { openCollapse } = this.state;
    const vfurl = getStorageItem(STORAGE_VENUE_FRIENDLY_URL)
    const ns = productless ? nsEvenly : nsVL;
    const headerBodyTranslation = t('logoutModal.descr', ns);
    const catalogueSearchTranslation = t('catalogue.searchPlaceholder', ns);

    const mobSearchClasses = classnames('wssearch', 'clearfix', { 'wsopensearch': this.state.mobSearch })
    const mobScanClasses = classnames('wsbasketicon', 'wsscanner', 'wspiricon', 'clearfix', { 'd-none': this.state.mobSearch })
    const accountClasses = classnames('wsmenu-click', { 'ws-activearrow': openCollapse === ACCOUNT })
    const langClasses = classnames('wsmenu-click', { 'ws-activearrow': openCollapse === LANG })
    const pvsClasses = classnames('wsmenu-click', { 'ws-activearrow': openCollapse === PVS })

    const bookingView = licenseDtls.bcc ? VIEW_BOOKING_BCC : VIEW_BOOKING

    const headerBookTranslation = licenseDtls.bcc ? t('header.book') : t('header.book').normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase();
    const headerChatTranslation = licenseDtls.bcc ? t('instantChat.title') : t('instantChat.title').normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase();

    const otherLangs = licenseDtls.langs
      .map(lang => (
        lang !== i18n.language && <React.Fragment key={lang}>
          <li><a onClick={() => { this.handleHamburger(false); this.changeLang(lang) }}>{ISO6391.getNativeName(lang)}</a></li>
        </React.Fragment>
      ));

    const navigationOptions = <>
      <li>
        {!productless && <a onClick={() => { this.handleHamburger(false); this.props.onNavigate('orders') }}>{t('header.orders', ns)}</a>}
      </li>
      {this.props.user && licenseDtls.booking && <li><a onClick={() => { this.handleHamburger(false); this.props.onNavigate(VIEW_MANAGE_BOOKING) }}>{t('header.bookManage')}</a></li>}
      {this.props.user && licenseDtls.chatDownload && <li><a onClick={() => { this.handleHamburger(false); this.props.onNavigate(VIEW_CHAT_HISTORY) }}>{t('header.chatHistory')}</a></li>}
      {this.props.user && <li><a onClick={() => { this.handleHamburger(false); this.props.onNavigate('settings') }}>{t('header.settings')}</a></li>}
      {this.props.user && <li><a onClick={() => { this.handleHamburger(false); this.handleShowLogoutModal() }}>{t('header.logout')}</a></li>}
    </>

    const pvsOptions = <>
      {agentJoined && !!customerID && <li id="callDetails" className="wsshopmyaccount order-1 clickable"><a onClick={() => this.props.toggleShowCallDetailsModal()}>{t('pvs.details')}</a></li>}
      {agentJoined && !!customerID && <li id="troubleshooting" className="wsshopmyaccount order-2 clickable"><a onClick={this.props.toggleShowCallTroubleshootingModal}>{t('call.troubleshootingModal.title')}</a></li>}
      {agentJoined && !!customerID && <li className="wsshopmyaccount order-3 clickable"><a onClick={this.handleFinishCallModal}>{t('call.finish')}</a></li>}
      {agentJoined && !!customerID && <hr className="my-0 d-none d-lg-block" />}
      <li className="wsshopmyaccount order-3 clickable"><a onClick={() => { this.handleHamburger(false); this.props.onNavigate(VIEW_USER_HOME) }}>{t('pvs.options.home')}</a></li>
      {process.env.REACT_APP_DEMO_DUMMY === "true" && <li className="wsshopmyaccount order-3 clickable"><a onClick={() => { this.handleHamburger(false); sessionStorage.setItem("dummyParticipants", "true"); this.props.onNavigate(VIEW_VIDEOCALL) }}>3-Way Call (dummy)</a></li>}
      {process.env.REACT_APP_DEMO_IMM === "true" && <li className="wsshopmyaccount order-3 clickable"><a onClick={() => { this.handleHamburger(false); sessionStorage.setItem("dummyParticipants", "true"); this.props.onNavigate(VIEW_IMM_MODE) }}>Translation Mode (dummy)</a></li>}
      {process.env.REACT_APP_DEMO_IMM === "true" && <li className="wsshopmyaccount order-3 clickable"><a onClick={() => { this.handleHamburger(false); sessionStorage.setItem("dummyParticipants", "true"); this.props.onNavigate(VIEW_IMM_MODE_TTS) }}>Translation Mode with TTS (dummy)</a></li>}
      {licenseDtls.in_person_mode && <li className="wsshopmyaccount order-3 clickable"><a onClick={() => { this.handleHamburger(false); this.props.onNavigate(VIEW_IN_PERSON_MODE) }}>Local Mode</a></li>}
      {licenseDtls.in_person_mode && process.env.REACT_APP_DEMO_DUMMY === "true" && <li className="wsshopmyaccount order-3 clickable"><a onClick={() => { this.handleHamburger(false); this.props.onNavigate(VIEW_IN_PERSON_MODE_PURE) }}>Local Mode (pure)</a></li>}
      {!!vfurl && licenseDtls.instant && <li className="wsshopmyaccount order-4 clickable"><a href={this.context.wasp_url + "/" + vfurl + "?tablet=true"}>{t('tabletMode.standBy.menuBtn')}</a></li>}
      {licenseDtls.audioOnlyMode && <li className="wsshopmyaccount order-3 clickable"><a onClick={() => { this.handleHamburger(false); setShowAudioOnlyRequestModal() }}>Audio-Only Mode <br /> (1-device operation)</a></li>}
      {licenseDtls.audioOnlyMode && <li className="wsshopmyaccount order-3 clickable"><a onClick={() => { this.handleHamburger(false); setShowAudioOnlyRequestModal(); sessionStorage.setItem("autoDetect", "true") }}>Audio-Only Mode v2 <br /> (1-device operation)</a></li>}
      <li className="wsshopmyaccount order-5 clickable"><a onClick={this.handleLogoutAgentModal}>{t('pvs.options.logout')}</a></li>
    </>

    const videoCallOptions = <>
      <li><a onClick={() => { this.handleHamburger(false); this.props.onNavigate(bookingView); this.props.createBooking && this.props.setInitBookingForm(true) }}>{headerBookTranslation}</a></li>
      <li><a onClick={() => { this.handleHamburger(false); isWorkingHour === false ? handleShowWorkingHoursModal("go live now") : user ? setShowInstantCallRequestModal() : handleShowUserAuthModal(setShowInstantCallRequestModal) }}>{t('instantCall.title')}</a></li>
    </>

    var pirIconClass = `fas color-pir-color d-inline fa-certificate fa-lg mr-1`

    if (pir && pir.bskt_amnt >= pir.pir.t2)
      pirIconClass += " pir-t2"
    else if (pir && pir.bskt_amnt >= pir.pir.t1)
      pirIconClass += " pir-t1"
    else if (pir)
      pirIconClass += " pir-spotlight"

    const clickLogoClass = `brand-logo${!isVideoCall || (!cusJoined && !productless) ? " clickable" : ""}`
    const clickLogoHandler = () => !isVideoCall || (!cusJoined && !productless) ? this.props.onNavigate(HOMEPAGE, "", "", true) : undefined

    const navControls = <nav className="wsmenu clearfix d-lg-flex justify-content-between h-100"><div className="overlapblackbg" onClick={() => this.handleHamburger()}></div>
      <ul className="wsmenu-list d-lg-flex justify-content-between">

        {!cusJoined && !productless && !hideCatalogue && <CategoryNav
          hamburger={this.state.openHamburger}
          isOpen={openCollapse === CATEGORY_NAV}
          onCollapse={() => this.openCollapse(CATEGORY_NAV)}
          onCategoryClick={this.handleCategories}
          openReloadModal={this.props.openReloadModal}
          venue={this.props.venue}
        />}

        {/* <!-- Search  --> */}
        <li className="wssearchbar mr-auto clearfix" style={{ width: "250px" }}>
          {!cusJoined && !hideSearch && <div className="topmenusearch">
            <input className="form-control" id="searchInput" type="search" aria-label="Enter text to search" placeholder={catalogueSearchTranslation} value={this.state.search} onChange={this.handleSearchText} onKeyPress={e => e.key === "Enter" && this.handleSearch()} />
            <button className="btnstyle" onClick={this.handleSearch}><i className="searchicon fas fa-search"></i></button>
          </div>}
        </li>
        {/* <!-- Search End --> */}

        {!cusJoined && (!arsMode || user || (isAgent && !!customerID)) && !productless && !this.state.openHamburger && <li className="wscarticon order-3 clickable"><a onClick={() => { this.handleHamburger(false); this.props.onNavigate('basket'); }}><i className="fas fa-shopping-basket" />
          {(user || isAgent) && <em className="roundpoint">{this.props.basketCount}</em>}
          <span className="hidetxt">{t('header.basket', ns)}</span></a></li>}

        {!cusJoined && !this.state.openHamburger && this.props.pir && <li className="wscarticon wspiricon order-3 clickable"><a onClick={() => { this.handleHamburger(false); this.props.openPIR(); }}><i className={pirIconClass} /></a></li>}

        {!cusJoined && this.props.licenseDtls.wishlist && <li className="wscarticon order-3 clickable"><a onClick={() => { this.handleHamburger(false); this.props.onNavigate('wishlist'); }}><i className="fas fa-heart"></i> <span className="hidetxt">{t('header.wishlist')}</span></a></li>}

        {!cusJoined && !hideSearch && <li className="wsshopmyaccount order-1 d-block d-lg-none clickable"><a onClick={() => { this.handleHamburger(false); this.setState({ mobSearch: !this.state.mobSearch }) }}><i className="fas fa-search"></i>{t('header.search', ns)}</a></li>}

        {!cusJoined && licenseDtls.booking && !arsMode && !licenseDtls.instant && currentView !== bookingView && <li className="wsshopmyaccount order-1 clickable"><a onClick={() => { this.handleHamburger(false); this.props.onNavigate(bookingView); this.props.createBooking && this.props.setInitBookingForm(true) }}>{headerBookTranslation}</a></li>}
        {!cusJoined && !licenseDtls.booking && !arsMode && licenseDtls.instant && <li className="wsshopmyaccount order-1 clickable"><a onClick={() => { this.handleHamburger(false); isWorkingHour === false ? handleShowWorkingHoursModal("go live now") : user ? setShowInstantCallRequestModal() : handleShowUserAuthModal(setShowInstantCallRequestModal) }}>{t('instantCall.title')}</a></li>}

        {!cusJoined && licenseDtls.booking && !arsMode && licenseDtls.instant && <li className="wsshopmyaccount order-2 clickable" onClick={() => this.openCollapse(VIDEO_CALL)}>
          <span className={accountClasses} onClick={() => this.openCollapse(VIDEO_CALL)}><i className="wsmenu-arrow"></i></span>
          <a><i className="fas fa-video m-lg-auto px-lg-2"></i><span className="d-lg-none">{t('header.videoCall')}</span></a>
          {this.state.openHamburger
            ? <Collapse tag="ul" isOpen={openCollapse === VIDEO_CALL} className="sub-menu">
              {videoCallOptions}
            </Collapse>
            : <ul className="sub-menu">
              {videoCallOptions}
            </ul>}
        </li>}

        {!cusJoined && !arsMode && licenseDtls.instantChat && !(licenseDtls.booking && licenseDtls.instant) && <li className="wsshopmyaccount order-2 clickable"><a onClick={() => { this.handleHamburger(false); isWorkingHour === false ? handleShowWorkingHoursModal("live chat") : user ? setShowInstantChatRequestModal() : handleShowUserAuthModal(setShowInstantChatRequestModal) }}>{headerChatTranslation}</a></li>}
        {!cusJoined && !arsMode && licenseDtls.instantChat && licenseDtls.booking && licenseDtls.instant && <li className="wsshopmyaccount order-2 clickable"><a onClick={() => { this.handleHamburger(false); isWorkingHour === false ? handleShowWorkingHoursModal("live chat") : user ? setShowInstantChatRequestModal() : handleShowUserAuthModal(setShowInstantChatRequestModal) }}><i className="fa fa-comment m-lg-auto px-lg-2"></i><span className="d-lg-none">{headerChatTranslation}</span></a></li>}

        {!cusJoined && !arsMode && licenseDtls.broadcast && currentView !== VIEW_EVENT_VIEWER && <li id="viewer" className="wsshopmyaccount order-2 clickable"><a onClick={() => { this.handleHamburger(false); this.props.onNavigate(VIEW_EVENT_VIEWER) }}><i className="fas fa-closed-captioning m-lg-auto px-lg-2"></i><span className="d-lg-none">{t('broadcast.viewer')}</span></a></li>}

        {(!cusJoined && !productless) && <li className="wsshopmyaccount order-1 d-lg-none clickable"><a onClick={() => { this.handleHamburger(false); this.props.onNavigate(VIEW_ABOUT); }}><i className="fas fa-info-circle"></i>{t('header.about')}</a></li>}

        {!cusJoined && !arsMode && (!productless || this.props.user) && (this.props.licenseDtls.hasCusReg || this.props.cookies.get("order_ref")) &&
          <li className="wsshopmyaccount order-2 clickable" onClick={() => this.openCollapse(ACCOUNT)}>
            <span className={accountClasses} onClick={() => this.openCollapse(ACCOUNT)}><i className="wsmenu-arrow"></i></span>
            <a><i className="fas fa-user m-lg-auto px-lg-2"></i><span className="d-lg-none">{t('header.account')}</span></a>
            {this.state.openHamburger
              ? <Collapse tag="ul" isOpen={openCollapse === ACCOUNT} className="sub-menu">
                {navigationOptions}
              </Collapse>
              : <ul className="sub-menu">
                {navigationOptions}
              </ul>}
          </li>}

        {/* Admin User's Navigation Items */}
        {isAdminUser && <>
          <li className="wsshopmyaccount navtext clickable" onClick={() => this.openCollapse(PVS)}>
            <span className={pvsClasses} onClick={() => this.openCollapse(PVS)}><i className="wsmenu-arrow"></i></span>
            <a><i className="fas fa-headset m-lg-auto px-lg-2"></i>{t('pvs.title')}</a>
            {this.state.openHamburger
              ? <Collapse tag="ul" isOpen={openCollapse === PVS} className="sub-menu">
                {pvsOptions}
              </Collapse>
              : <ul className="sub-menu">
                {pvsOptions}
              </ul>}
          </li>
        </>}
        {/* Admin User's Navigation Items End */}

        {/* <! -- Finish Call Modal --> */}
        {this.state.showFinishCallModal && <FinishCallModal
          close={this.handleFinishCallModal}
          hasProducts={this.props.basket}
          meetingID={this.props.meetingID}
          VScusDtls={this.props.VScusDtls}
          resetVScusDtls={this.props.resetVScusDtls}
          clearProducts={this.props.clearProducts}
          removeToken={this.props.removeToken}
          token={this.props.vsToken}
          setView={this.props.setView}
          logout={this.props.logout}
          openReloadModal={this.props.openReloadModal}
          endMeeting={this.props.endMeeting}
          maximiseVideoCall={this.props.maximiseVideoCall}
          sendBasketMessage={this.props.sendChatMessage}
          productless={productless}
        />}
        {/* <! -- Send Basket Modal End --> */}

        {/* <! -- Logout Agent modal --> */}
        <Modal id="logout" className="modal fade show" tabIndex="-1" centered={true} role="dialog" isOpen={this.state.showLogoutAgentModal}>
          <ModalHeader toggle={this.handleLogoutAgentModal}>
            <div className="modal-title h5">
              <i className="fa fa-exclamation-circle fa-sm mr-2" />
              {t('logoutUserModal.title')}
            </div>
          </ModalHeader>
          <ModalFooter>
            <Button type="button" className="btn btn-dark" onClick={this.handleLogoutAgentModal} data-dismiss="modal">{t('logoutUserModal.cancelBtn')}</Button>
            <Button type="button" className="btn btn-primary" onClick={this.logoutAgent} data-dismiss="modal">{t('logoutUserModal.confirmBtn')}</Button>
          </ModalFooter>
        </Modal>
        {/* <! -- Logout Agent modal End --> */}

        {licenseDtls.langs.length <= 1 ? <p style={{ lineHeight: "57px" }}>&nbsp;</p> :
          <li className="wsflagicon clearfix order-4 clickable" onClick={() => this.openCollapse(LANG)}>
            <span className={langClasses} onClick={() => this.openCollapse(LANG)}><i className="wsmenu-arrow"></i></span>
            <a><i className="fas fa-flag m-lg-auto px-lg-2"></i>{i18n.language.toUpperCase()}</a>
            {this.state.openHamburger
              ? <Collapse tag="ul" isOpen={openCollapse === LANG} className="sub-menu">
                {otherLangs}
              </Collapse>
              : <ul className="sub-menu">
                {otherLangs}
              </ul>}
          </li>}

      </ul>

      <Modal className="logout-modal" centered={true} zIndex={"auto"} isOpen={this.state.showLogoutModal} toggle={this.handleCloseLogoutModal}>
        <ModalHeader>
          <i className="fas fa-sign-out-alt fa-sm mr-2"></i>{t('logoutModal.title')}
        </ModalHeader>
        <ModalBody>
          <p className="small">{headerBodyTranslation}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="outline-dark" onClick={this.logout}>{t('logoutModal.confirmBtn')}</Button>
          <Button color="primary" onClick={this.handleCloseLogoutModal}>{t('logoutModal.cancelBtn')}</Button>
        </ModalFooter>
      </Modal>

    </nav>

    return (
      <>
        <div className="headerfull bg-white border-bottom">
          <div className="wsmain d-flex justify-content-between">
            <div className="smllogo"><img src={this.props.licenseDtls.logo} className={clickLogoClass} alt={this.props.licenseDtls.retailer} onClick={clickLogoHandler} /></div>

            {!hideNavControls && navControls}

          </div>
        </div>

        {/* MOBILE NAVIGATION */}

        <div className="wsmobileheader bg-white border-bottom clearfix">
          {(!cusJoined || licenseDtls.langs.length > 1) && <a id="wsnavtoggle" className="wsanimated-arrow" onClick={() => this.handleHamburger()}><span></span></a>}
          <span className="smllogo h-auto">
            <a>
              <img src={this.props.licenseDtls.logo} className={clickLogoClass} alt={this.props.licenseDtls.retailer} onClick={clickLogoHandler} />
            </a>
          </span>

          {!hideSearch && <div className={mobSearchClasses} onClick={() => this.setState({ mobSearch: !this.state.mobSearch })}>
            <i className="wsclosesearch fas fa-times"></i>
            <div className="wssearchform clearfix">
              <div onClick={e => e.stopPropagation()}>
                <input id="search" type="search" aria-label="Enter text to search" placeholder={catalogueSearchTranslation} value={this.state.search} onChange={this.handleSearchText} onKeyPress={(e) => { if (e.key === "Enter") { this.handleSearch(); document.activeElement.blur(); } }} />
              </div>
            </div>
          </div>}

          {this.props.pir && <div onClick={this.props.openPIR} className={mobScanClasses}>
            <a>
              <i className={pirIconClass} />
            </a>
          </div>}
        </div>
        {/* END MOBILE NAVIGATION */}
      </>
    );
  }

  componentDidUpdate(prevState, prevProps) {
    prevState.search !== this.props.search && this.setState({ search: this.props.search })

    if (!!prevProps.search && this.props.clearSearch)
      this.setState({ search: "" })
  }

  handleSearchText = (e) => {
    this.setState({
      search: e.target.value
    }, () => this.props.clearSearch && this.props.clearSearchFlag("catalogue"));
  }

  handleSearch = () => {
    var filters = {
      search: this.state.search.trim()
    }

    this.setState({
      search: filters.search
    })

    pushHistoryState('catalogue', "forcePush", { filters: filters })
    sessionStorage.setItem("filters", JSON.stringify(filters))
    this.props.onNavigate("catalogue")
  }

  handleCategories = (k1, n1, k2, n2, k3, n3, b1, b2, b3) => {
    var lvl = 0

    if (k3)
      lvl = 3
    else if (k2)
      lvl = 2
    else
      lvl = 1

    var filters = {
      catLvl: lvl,
      catLvl1: { k1: k1, n1: n1, banner: b1 },
      catLvl2: { k2: k2, n2: n2, banner: b2 },
      catLvl3: { k3: k3, n3: n3, banner: b3 },
    }

    pushHistoryState('catalogue', "forcePush", { filters: filters })
    sessionStorage.setItem("filters", JSON.stringify(filters))
    this.props.onNavigate("catalogue")
    this.props.updateCatFromPrdView(filters)
    this.props.onBackBtn(false)
  }

  handleHamburger = (openHamburger) => {
    if (typeof openHamburger !== "boolean")
      openHamburger = !this.state.openHamburger
    this.setState({ openHamburger: openHamburger })
    if (openHamburger) {
      document.body.classList.add("wsactive")
      document.documentElement.className += " wsactivecustom"
    } else {
      document.body.classList.remove("wsactive")
      document.documentElement.className = "cover-body"
    }
  }

  changeLang = (lang) => {
    i18n.changeLanguage(lang);
    this.props.sortCCLangs();
  }

  handleShowLogoutModal = () => {
    this.setState({ showLogoutModal: true });
  }

  handleCloseLogoutModal = () => {
    this.setState({ showLogoutModal: false });
  }

  logout = () => {
    this.props.logout()
    this.handleCloseLogoutModal()
  }

  openCollapse = (currentNav) => {
    this.setState({ openCollapse: this.state.openCollapse !== currentNav ? currentNav : null });
  }

  handleFinishCallModal = () => {
    this.setState((oldState) => ({
      showFinishCallModal: !oldState.showFinishCallModal
    }));
  }

  handleLogoutAgentModal = () => {
    this.setState((oldState) => ({
      showLogoutAgentModal: !oldState.showLogoutAgentModal
    }));
  }

  logoutAgent = () => {
    const { api } = this.context;
    const logoutEndpoint = api + "/user/logout";

    // Make a fetch request to the logout endpoint
    fetch(logoutEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.props.vsToken,
      },
    })
      .catch((error) => {
        console.log('Logout error:', error);
      });

    console.log('AGENT LOGOUT', logoutEndpoint)

    this.props.resetVScusDtls();
    this.props.removeToken();
    this.handleLogoutAgentModal();
    this.handleHamburger();
    this.props.removeVSToken();
    this.props.endMeeting();
    this.props.maximiseVideoCall();
    clearStorageItem(STORAGE_VENUE_FRIENDLY_URL);
    clearStorageItem(STORAGE_VENUE_ID);
  }
}

Navigation.contextType = ConfigContext;

export default withTranslation()(withCookies(Navigation));
